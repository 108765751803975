.hideHeader {
    embed {
        position: relative;
        
        .wp-block-template-part{
            background-color: red;
        }
    }
    height: 100%;
}

.wp-block-template-part{
    background-color: red;
}
